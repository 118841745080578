
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Registration } from '@/models/registrations/registration.class';

const registrationModule = namespace('registration');

@Component({
  components: {},
})
export default class BillingRedirect extends Vue {
  @registrationModule.Action('fetchRegistration')
  fetchRegistrationById!: (id: string) => Registration;

  @registrationModule.Action('createPayment')
  createPayment!: (id: string) => Registration;

  @registrationModule.Getter('registration')
  registration!: Registration;

  async created() {
    const registrationId = this.$route.params?.id;
    await this.fetchRegistrationById(registrationId);

    if (!this.registration) {
      this.$router.push({ name: 'pageNotFound' });
    }

    await this.createPayment(registrationId);

    if (this.registration.paymentCheckoutUrl) {
      window.location.replace(this.registration.paymentCheckoutUrl);
    }
  }
}
